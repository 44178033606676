import React from 'react';
import dynamic from 'next/dynamic';
import { ShimmerImage } from '@pitchero/react-ui';

const Video = dynamic(() => import('./index'), {
  loading: () => <ShimmerImage preset="card" fullWidth />,
  ssr: false,
});

const DynamicallyImportedVideo = (props) => <Video {...props} />;

export default DynamicallyImportedVideo;
