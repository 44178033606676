import { AbsoluteContainer, Image, RelativeContainer, Typography } from '@pitchero/react-ui';
import ArrowLeftIcon from 'icons/ArrowLeft';
import ArrowRightIcon from 'icons/ArrowRight';
import PropTypes from 'prop-types';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React from 'react';
import styled from 'styled-components';

const ArrowButton = styled.div`
  border: 2px solid white;
  color: white;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ArticleAlbum = ({ images }) => {
  return (
    <CarouselProvider naturalSlideWidth={16} naturalSlideHeight={9} totalSlides={images.length}>
      <RelativeContainer>
        <Slider>
          {images.map((image, index) => (
            <Slide index={index}>
              <RelativeContainer>
                <Image
                  src={image.url}
                  alt={image.title}
                  transformation="fit"
                  width={980}
                  height={550}
                  style={{ width: '100%', height: 'auto', aspectRatio: '16 / 9' }}
                />
                {image.title && (
                  <AbsoluteContainer
                    bottom="0"
                    left="0"
                    right="0"
                    style={{
                      background: 'linear-gradient(to top, rgba(50,50,50,0.75), rgba(50,50,50,0))',
                      padding: '10px',
                    }}
                  >
                    <Typography preset="description" color="white" size={16}>
                      {image.title}
                    </Typography>
                  </AbsoluteContainer>
                )}
              </RelativeContainer>
            </Slide>
          ))}
        </Slider>
        <AbsoluteContainer top="0" left="0" style={{ height: '100%' }}>
          <ButtonBack
            style={{
              height: '100%',
              padding: '0 10px',
            }}
          >
            <ArrowButton>
              <ArrowLeftIcon size={18} color="#fff" />
            </ArrowButton>
          </ButtonBack>
        </AbsoluteContainer>
        <AbsoluteContainer top="0" right="0" style={{ height: '100%' }}>
          <ButtonNext
            style={{
              height: '100%',
              padding: '0 10px',
            }}
          >
            <ArrowButton>
              <ArrowRightIcon size={18} color="#fff" />
            </ArrowButton>
          </ButtonNext>
        </AbsoluteContainer>
      </RelativeContainer>
    </CarouselProvider>
  );
};

ArticleAlbum.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ).isRequired,
};

export default ArticleAlbum;
